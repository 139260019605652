:root {
  --form-control-padding-x: var(--space-sm);
  --form-control-padding-y: var(--space-sm);
  // --form-control-radius: 0.25em;
}

.form-control {
  color: var(--color-black);
  line-height: 1.4;
  background-color: var(--color-bg);
  border: 1px solid var(--color-contrast-low);
  transition: all 0.2s;

  &::-webkit-input-placeholder {
    color: var(--color-contrast-medium);
  }

  &::-moz-placeholder {
    color: var(--color-contrast-medium);
  }

  &:-ms-input-placeholder {
    color: var(--color-contrast-medium);
  }

  &:-moz-placeholder {
    color: var(--color-contrast-medium);
  }

  &:hover {
    border-color: var(--color-contrast-medium);
  }

  &:focus {
    border-color: var(--color-primary);
    box-shadow: 0px 0px 0px 1px var(--color-primary);
    outline: none;
  }
}

.form-control[disabled],
.form-control[readonly] {
  cursor: not-allowed;
}

.form-control[aria-invalid="true"] {
  border-color: var(--color-error);

  &:focus {
    box-shadow: 0px 0px 0px 1px var(--color-error);
  }
}

.has-error {
  .form-control {
    border-color: var(--color-error);
  }
}

.has-success {
  .form-control {
    border-color: var(--color-success);
  }
}

.messages {
  font-size: 0.875rem;
  color: var(--color-error);
}

.form-legend {
  color: var(--color-contrast-higher);
  font-family: var(--font-primary);
  font-size: var(--text-md);
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: var(--space-sm);
}

.form-label {
  color: alpha(var(--color-contrast-high), 0.65);
  font-size: var(--text-sm);
}

// size
.form-control {
  font-size: var(--input-font-size, 1em);
}
.form-control--sm {
  --input-font-size: 0.8em;
}
.form-control--md {
  --input-font-size: 1.05em;
}
.form-control--lg {
  // --input-font-size: 0.8em;
  padding: 1em 1.5em;
}

.form-control-wrapper--lg {
  --form-control-padding-x: var(--space-md);
  --form-control-padding-y: var(--space-md);
}
