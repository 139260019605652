:root,
[data-theme="default"] {
  // main
  @include defineColorHSL(--color-primary-darker, 197, 55%, 21%);
  @include defineColorHSL(--color-primary-dark, 197, 55%, 31%);
  @include defineColorHSL(--color-primary, 197, 55%, 41%);
  @include defineColorHSL(--color-primary-light, 197, 55%, 51%);
  @include defineColorHSL(--color-primary-lighter, 197, 55%, 61%);

  @include defineColorHSL(--color-accent-darker, 14, 68%, 29%);
  @include defineColorHSL(--color-accent-dark, 14, 68%, 39%);
  @include defineColorHSL(--color-accent, 14, 68%, 49%);
  @include defineColorHSL(--color-accent-light, 14, 68%, 59%);
  @include defineColorHSL(--color-accent-lighter, 14, 68%, 69%);

  @include defineColorHSL(--color-black, 240, 8%, 12%);
  @include defineColorHSL(--color-white, 0, 0%, 100%);

  // feedback
  @include defineColorHSL(--color-warning-darker, 46, 100%, 41%);
  @include defineColorHSL(--color-warning-dark, 46, 100%, 51%);
  @include defineColorHSL(--color-warning, 46, 100%, 61%);
  @include defineColorHSL(--color-warning-light, 46, 100%, 71%);
  @include defineColorHSL(--color-warning-lighter, 46, 100%, 81%);

  @include defineColorHSL(--color-success-darker, 94, 48%, 36%);
  @include defineColorHSL(--color-success-dark, 94, 48%, 46%);
  @include defineColorHSL(--color-success, 94, 48%, 56%);
  @include defineColorHSL(--color-success-light, 94, 48%, 66%);
  @include defineColorHSL(--color-success-lighter, 94, 48%, 76%);

  @include defineColorHSL(--color-error-darker, 349, 75%, 31%);
  @include defineColorHSL(--color-error-dark, 349, 75%, 41%);
  @include defineColorHSL(--color-error, 349, 75%, 51%);
  @include defineColorHSL(--color-error-light, 349, 75%, 61%);
  @include defineColorHSL(--color-error-lighter, 349, 75%, 71%);

  // color contrasts
  @include defineColorHSL(--color-bg, 0, 0%, 100%);
  @include defineColorHSL(--color-contrast-lower, 0, 0%, 95%);
  @include defineColorHSL(--color-contrast-low, 240, 1%, 83%);
  @include defineColorHSL(--color-contrast-medium, 240, 1%, 48%);
  @include defineColorHSL(--color-contrast-high, 240, 4%, 20%);
  @include defineColorHSL(--color-contrast-higher, 240, 8%, 12%);
}
