$breakpoints: (
  xs: 32rem,
  // ~512px
    sm: 48rem,
  // ~768px
    md: 62rem,
  // ~992px
    lg: 80rem,
  // ~1280px
    xl: 90rem // ~1440px
);

@mixin breakpoint($breakpoint, $logic: false) {
  @if ($logic) {
    @media #{$logic} and (min-width: map-get($map: $breakpoints, $key: $breakpoint)) {
      @content;
    }
  } @else {
    @media (min-width: map-get($map: $breakpoints, $key: $breakpoint)) {
      @content;
    }
  }
}
