.card {
  &[class*="grid-gap"] {
    margin-bottom: 0;
  }

  > * + * {
    @media (max-width: 992px) {
      margin-top: 1.5rem;
    }
  }
}

.card__item {
  position: relative;
  --text-lg: 1.2em;
  padding: var(--space-md) var(--space-lg);
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.14);
  border-radius: 0.25rem;
  background-color: var(--color-white);

  @include breakpoint(xs) {
    padding: var(--space-lg) var(--space-xl);
  }

  &--vector {
    padding-bottom: 10rem;
    margin-bottom: 1rem;

    @include breakpoint(xs) {
      padding-bottom: 10rem;
      margin-bottom: 1rem;
    }
  }

  .vector {
    &--left {
      svg {
        position: absolute;
        top: 63%;
        left: -2rem;
        width: 8rem;
        height: auto;
      }
    }
    &--right {
      svg {
        position: absolute;
        top: 63%;
        right: -2rem;
        width: 8rem;
        height: auto;
      }
    }

    @include breakpoint(md) {
      &--left {
        svg {
          position: absolute;
          top: 70%;
          left: -2rem;
          width: 12rem;
          height: auto;
        }
      }
      &--right {
        svg {
          position: absolute;
          top: 70%;
          right: -2rem;
          width: 12rem;
          height: auto;
        }
      }
    }
  }
}

.card.card--primary {
  padding: var(--space-md) 0;
  background-color: var(--color-primary);
  color: alpha(var(--color-white), 0.8);
  border-radius: 0.25rem;

  h1,
  h2,
  h3,
  h4 {
    color: var(--color-white);
    margin-top: var(--space-xs);
  }
}

.card.card--clear {
  padding-top: var(--space-md);
  padding-bottom: var(--space-md);

  .card__item {
    box-shadow: none;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;

    & + .card__item {
      margin-top: var(--space-md);

      @include breakpoint(md) {
        box-shadow: -1px 0 0 var(--color-contrast-lower);
        margin-top: 0;
      }
    }
  }
}
